
.container {
  display: flex;
  flex-direction: row;
  margin: 20px;
}


.left-section {
  flex: 1;
  margin-right: 40px;
  border-right: 1px solid lightgray;
  padding-top: 60px;
}

.left-section h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 300;
}

.left-section ul {
  list-style-type: none;
  padding: 0;
}

.left-section li {
  cursor: pointer;
  padding: 10px 0;
  color: #003265;
  font-weight: 500;
  transition: color 0.3s ease;
}

.left-section li:hover {
  color: #0068d0;
}

.main-section {
  flex: 3;
}

.category-div {
  margin-bottom: 20px;
}

.category-div button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.category-div button:hover {
  background-color: #0073e6;
  color: white;
}

.author-info {
  margin: 10px 0;
  margin-bottom: 30px;
}

.author-info h4 {
  font-weight: 500;
}

.author-info h4 span {
    cursor: pointer;
    color: dodgerblue;
}

.author-info p {
  font-size: 0.9em;
  color: #666;
}

article {
  line-height: 1.6;
}

article h1 {
  font-size: 3.5em;
  margin-bottom: 10px;
  color: dodgerblue;
}

.lvl2_heading {
  font-size: 1.8em;
  margin-top: 20px;
}

.para {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 50px;
}

.lvl3_heading {
  font-size: 1.5em;
  margin-top: 15px;
  color: blue;
}

.list {
  margin: 15px 0;
  padding-left: 20px;
  margin-bottom: 50px;
}

ul li {
  margin-bottom: 10px;
}

.post_figure {
  margin-top: 20px;
  margin-bottom: 70px;
}

.post_figure figcaption {
  color: blue;
}

.post_figure img {
  object-fit: contain;
  width: 100%;
  margin-bottom: 25px;
}

.conclusion {
    margin-bottom: 100px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-section {
    margin-bottom: 30px;
  }

  .category-div button {
    padding: 8px;
    margin-bottom: 10px;
  }

  article h1 {
    font-size: 1.8em;
  }

  .lvl2_heading {
    font-size: 1.6em;
  }

  .para {
        margin-bottom: 50px;
    }

    .list {
        margin-bottom: 50px;
    }

    .lvl3_heading {
    font-size: 1.4em;
    color: blue;
  }

  .post_figure {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  
  .post_figure figcaption {
    color: blue;
    font-size: small;
  }
  
  .post_figure img {
    object-fit: contain;
    width: 100%;
    margin-bottom: 25px;
  }

  .conclusion {
    margin-bottom: 100px;
    }
}
