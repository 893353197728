@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@700&family=Crimson+Text&family=Fira+Sans&family=Fira+Code&family=Roboto+Flex&family=Lexend&family=Quicksand:ital,wght@0,400;1,300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spectral+SC:wght@300&display=swap');


* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Fira Sans';
}


.grad {
  background: radial-gradient(circle at top left, #add8e69e 0%, transparent 20%),
              radial-gradient(circle at top right, rgba(82, 0, 159, 0.185) 0%, transparent 20%);
}


/* @media (max-width: 768px) {
  body ul {
      margin-block: 30px !important;
  }

  body ul li {
    margin-block: 35px;
    font-family: 'Quicksand' !important;
  }

  body ul li strong {
    display: block;
    font-size: larger;
    font-family: 'Quicksand' !important;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  body h2 {
    font-size: 30px;
    margin-top: 30px;
  }

  body h3 {
    font-size: x-large;
  }

  body p {
    font-size: larger;
    margin-bottom: 20px;
  }
} */